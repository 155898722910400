import { useEffect } from "react";
import { useSession, signIn } from "next-auth/client";
import { useRouter } from "next/router";

export const useEnsureLogin = (dest) => {
  const [session, loading] = useSession();
  const router = useRouter();
  useEffect(() => {
    if (loading) return;
    if (!session) return signIn("xero", { callbackUrl: dest });
    if (dest) return router.push(dest);
  }, [session, loading, dest]);

  return loading;
};
