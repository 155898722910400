import Head from "next/head";
import { useEnsureLogin } from "pkgs/hooks";

export default function Home() {
  useEnsureLogin("/upload");

  return (
    <div>
      <Head>
        <title>Create Next App</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>
        <h1>Welcome to Dragon Sync</h1>

        <p>We are checking your Login Status</p>
      </main>
    </div>
  );
}
